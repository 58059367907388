
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.save-button.clicked {
  background-color: red;
}
/* Your existing styles for tabs */
.tabs_wrapper .tab-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  padding: 10px; /* Add padding to improve click area */
}

.tabs_wrapper .tab-item.active {
  border-bottom: 2px solid #1E243B; /* Adjust the color according to your design */
}

.tabs_wrapper .tab-separator {
  width: 277px;
  height: 4px;
  background-color: #D9D9D9;
  margin: auto 0;
}

/* Additional styles for tab content */
.tabs_wrapper .tab-content {
  display: none; /* Hide all tab content by default */
}

.tabs_wrapper .tab-content.active {
  display: block; /* Show the content of the active tab */
  margin-top: 10px; /* Add margin to separate content from tabs */
}
.tabs_wrapper .tab {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.tabs_wrapper .tab > button > img {
  width: 40px;
  height: 40px;
}

.tab_span {
  position: relative;
}

.tab_span hr {
  position: absolute;
  height: 4px;
  background: #D9D9D9;
  right: 0;
  left: 106px;
  bottom: 7px;
}


@media only screen and (min-width:768px){
  .tab_span hr {
      width: 220px !important;
  }
}
@media only screen and (min-width:1024px){
  .tab_span hr {
      width: 302px !important;
  }
}
/* select{
  -moz-appearance: none;
  -webkit-appearance: none;
  -webkit-border-radius: 0px;
  appearance: none;
 } */
 .with_border {
  border-bottom: 1px solid #6F7780; /* Add your border color here */
}

.with_border:last-child {
  border-bottom: none !important; /* Remove bottom border for the last tab */
}
/* Change scrollbar width */
::-webkit-scrollbar {
  width: 4px; /* Adjust the width according to your preference */
}

/* Change scrollbar track color (background) */
::-webkit-scrollbar-track {
  background-color: #555; /* Adjust the color according to your design */
  display: none;
}

/* Change scrollbar handle color */
::-webkit-scrollbar-thumb {
  background-color: #A8C523; /* Adjust the color according to your design */
}

/* Change scrollbar handle on hover */
::-webkit-scrollbar-thumb:hover {
  background-color: #555; /* Adjust the color on hover */
}

:focus-visible {
  outline: none;
  color: #1E243B;
}





















@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


@media (max-width: 768px) {
  .sidebar {
    width: 0;
    height: 100%;
    position: fixed;
    z-index: 99;
    top: 0;
    left: 0;
    background-color: #111;
    overflow-x: hidden;
    transition: 0.5s; 
  }
  
  .sidebar.active {
    width: 100%; 
  }
}

