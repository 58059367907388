@tailwind base;
@tailwind components;
@tailwind utilities;


/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */

body{
  font-family: 'Poppins', sans-serif;
  overflow: hidden;
}
li.active_li::after {
position: absolute;
top: 0;
left: -23px;
width: 4px;
height: 48px;
content: "";
z-index: 1;
background: #a8c523;
border-radius: 10px;
}
.small_header{
  display: none;
}
/* for toggle */
.toggle {
  display: inline-block;
  position: relative;
  cursor: pointer;
}

.toggle input {
  display: none;
}

.toggle__slider {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  border-radius: 34px;
  transition: 0.4s;
}

.toggle input:checked + .toggle__slider {
  background-color: #1E243B;
}

.toggle__slider:before {
  position: absolute;
  content: "";
  height: 19px;
  width: 19px;
  left: 3px;
  top: 0;
  bottom: 0px;
  background-color: white;
  border-radius: 50%;
  transition: 0.4s;
  margin: auto;
}

.toggle input:checked + .toggle__slider:before {
  transform: translateX(26px);
}
/* width */
.custom_scroll_bar::-webkit-scrollbar {
  width: 6px;
}

/* Track */
.custom_scroll_bar::-webkit-scrollbar-track {
 
  border-radius: 10px;
}
 
/* Handle */
.custom_scroll_bar::-webkit-scrollbar-thumb {
  background: #A8C523; 
  border-radius: 10px;
}
/* end toggle */

.active_chat {
  background: #fff;
  margin: 0px !important;
  padding: 8px 15px;
  border-top: 1px solid rgba(30, 36 , 59 , 0.03);
}
.custom_hover_letshireme>div:hover button {
  
  background: #d9d9d9;
}

.custom_after_element button:hover::after , .custom_after_element button:hover::before {

  display: none;
}
.shadow_effect {
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  box-shadow: 0px 0px 137px 28px rgba(174, 189, 103, 0.49);
  border-radius: 45px;
}
.arrow_component:after {
  /* background-image: url("../arroW_2.png"); */
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 1;
  background-size: cover;
  top: -101%;
  bottom: 0;
  right: -68%;
  margin: auto;
  z-index: 2;
  background-size: 65%;
  background-repeat: no-repeat;
}
.arrow_component_2:after {
  /* background-image: url("../arrow_1.png"); */
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 1;
  background-size: cover;
  top: calc(100% + 120px);
  right: -68%;
  margin: auto;
  bottom: 0;
  z-index: 2;
  background-size: 65%;
  background-repeat: no-repeat;
}
.container{
  max-width: 1500px;
}
@media only screen and (max-width:1500px){
  .container{
      max-width: 100% !important;
      padding: 0 15px;
      width: 100%;
  }
}
@media only screen and (max-width:991px){
  .arrow_component:after {
     top: -49%;
     right: -69%;
  }
  .arrow_component_2:after {
     top: calc(100% + 87px);
     right: -73%;
  }
}
/* @media only screen and (max-width:500px){
  body .sidebar_design {
    width: 100vw;
    justify-content: space-between;
  }
} */
@media only screen and (max-width:767px){
  body .sidebar_design {
    width: 100vw;
    justify-content: space-between;
    padding: 12px;
  }
  .sidebar li {
      flex-direction: column;
      align-items: center;
      gap: 4px;
      font-size: 14px;
  }
  .sidebar li img {
      margin-right: 0;
  }
  .logo_header {
      display: none;
  }
  .sidebar ul {
      padding: 10px;
  }
  body .small_header {
      width: 50px;
      height: 50px;
      margin-bottom: 40px;
  }
  body .send_btn {
      padding: 10px;
  }
  .small_header{
      display: block;
  }
  .send_btn img {
      width: 20px;
      height: 20px;
      margin-right: 0px;
      max-width: max-content;
  }
  .send_btn span {
      font-size: 12px;
      line-height: normal;
      padding: 10px;
      background-color: #A8C523;
      border-radius: 50px;
  }
  body .main-content_inner {
      justify-content: start;
      padding-top: 0px;
  }
  body .small_text_mobile {
      font-size: 16px;
      margin-top: 15px;
  }
  body .small_btn_post {
      font-size: 16px;
  }
  .profile_name {
      display: none;
  }
  img.profile_image {
      width: 20px;
      height: 20px;
  }
  .profile_img{
    width: 42px !important;
    height: 42px !important;
  }
  .left_dropdown_profile {
      margin-left: 0px;
  }
  body .sticky_navbar_top {
      padding: 9px 15px;
      height: 60px;
      box-shadow: 0px 0px 27px 1px #99939326;
  }
  
  body .small_btn_post {
      width: max-content;
      padding: 10px 50px;
      height: max-content;
  }
  body li.active_li::after {
    left: -9px;
  }
  .custom_after_element button:after ,  .custom_after_element button:before {
      display: none;
  }
  .arrow_component:after , .arrow_component_2:after {
    display: none;
  }

}

.error-msg {
  color: red;
  padding: 10px;
}

.overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgb(189, 189, 189, 0.4);
  z-index: 999;
}

.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  z-index: 1000;
}


/* Accordion container */
.accordion {
  width: 100%;
}

/* Accordion item */
.accordion-item {
  border-bottom: 1px solid #ccc;
}

/* Accordion title */
.accordion-title {
  background-color: #f4f4f4;
  color: #333;
  cursor: pointer;
  padding: 15px;
  font-size: 18px;
  border-bottom: 1px solid #ddd;
  transition: background-color 0.3s ease;
}

.accordion-title:hover {
  background-color: #e0e0e0;
}

/* Accordion content */
.accordion-content {
  padding: 15px;
  display: none;
  background-color: #fff;
  border-bottom: 1px solid #ddd;
}

/* Open state styles */
.accordion-title.open {
  background-color: #e0e0e0;
}

.accordion-content.open {
  display: block;
}
